<template>
<div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-7">
                        <div class="text-primary p-4">
                            <h5 class="text-primary">Đăng ký vào hệ thống</h5>
                        </div>
                    </div>
                    <div class="col-5 align-self-end">
                        <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <b-form class="p-2">
                    <div v-if="isShow" class="alert alert-danger" role="alert">
                        {{statusMessage}}
                    </div>
                    <b-form-group id="input-group-1" label="+84" label-for="input-1">
                        <b-form-input id="input-1" v-model="phNo" type="text" placeholder="Nhập số điện thoại">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-1" label="Tên" label-for="input-1">
                        <b-form-input id="input-1" v-model="name" type="text" placeholder="Tên">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <label>Tỉnh/ thành</label>
                        <b-form-select v-model="province" :options="provinces">
                        </b-form-select>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Password" label-for="input-2">
                        <b-form-input id="input-2" v-model="password" type="password" placeholder="Nhập mật khẩu"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Password" label-for="input-2">
                        <b-form-input id="input-2" v-model="rePassword" type="password" placeholder="Nhập mật khẩu"></b-form-input>
                    </b-form-group>
                    <div class="mt-3">
                        <div id="recaptcha-container"></div>
                        <div v-if="showSignUpMessage" class="alert alert-danger" role="alert">
                            {{signUpMessage}}
                        </div>
                        <b-button @click.prevent="sendOtp" type="submit" variant="primary" class="btn-block">
                            Đăng ký
                        </b-button>
                    </div>
                </b-form>
            </div>
            <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
            <p>
                © Powered by HTX Nông Nghiệp Số
            </p>
        </div>
        <b-modal v-model="otpModal" id="modal-prevent-closing" ref="modal" title="Nhập OTP" hide-footer>
            <form ref="form">
                <b-form-group label="OTP" label-for="name-input" invalid-feedback="OTP">
                    <b-form-input id="name-input" v-model="otp" required></b-form-input>
                </b-form-group>
                <div v-if="showVerifyMessage" class="alert alert-danger" role="alert">
                    {{verifyMessage}}
                </div>
                <b-button @click.prevent="verifyOtp" type="submit" variant="primary" class="btn-block" v-b-modal.modal-prevent-closing>
                    Xác nhận
                </b-button>
            </form>
        </b-modal>
        <!-- end row -->
    </div>
    <!-- end col -->
</div>
</template>

<script>
import firebase from 'firebase'
import {
    mapActions,
    mapState
} from "vuex";
import {
    httpClient
} from "../../_utils/httpClient";

export default {
    name: 'Register',
    computed: {
        ...mapState('account', ['message']),
        statusMessage() {
            return this.message
        },
    },
    data() {
        return {
            isShow: false,
            showSignUpMessage: false,
            signUpMessage: '',
            showVerifyMessage: false,
            verifyMessage: '',
            otpModal: false,
            phNo: '',
            password: '',
            rePassword: '',
            appVerifier: '',
            otp: '',
            province: 'Thành phố Hà Nội',
            provinces: ['Thành phố Hà Nội'],
            name: ''
        }
    },
    methods: {
        ...mapActions('account', ['register']),
        async sendOtp() {
            if (this.phNo.length !== 9) {
                this.showSignUpMessage = true
                this.signUpMessage = `Sai định dạng số điện thoại.`
            } else if (this.password === '' || this.password !== this.rePassword) {
                this.showSignUpMessage = true
                this.signUpMessage = `Nhập lại mật khẩu không khớp.`
            } else {
                delete window.confirmationResult
                let countryCode = '+84'
                let phoneNumber = countryCode + this.phNo
                let appVerifier = this.appVerifier
                try {
                    window.confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                    console.log('SMS sent')
                    this.showVerifyMessage = false
                    this.verifyMessage = ``
                    this.otp = ``
                    this.otpModal = true

                } catch (e) {
                    console.log('e', e)
                }
            }
        },
        async verifyOtp() {
            if (this.otp.length !== 6) {
                this.showVerifyMessage = true
                this.verifyMessage = `Sai mã xác thực`
            } else {
                let code = this.otp
                try {
                    let result = await window.confirmationResult.confirm(code)
                    console.log('result', result)
                    let user = result.user;
                    let token = await user.getIdToken()
                    this.register({
                        id: '+84' + this.phNo,
                        password: this.password,
                        province: this.province,
                        name: this.name,
                        // id: '+84' + this.phNo,
                        token
                    }).then(data => {
                        this.showVerifyMessage = true
                        this.verifyMessage = data
                    })
                } catch (e) {
                    this.showVerifyMessage = true
                    this.verifyMessage = `Sai mã xác thực`
                }
            }
        },
        initReCaptcha() {
            setTimeout(() => {
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': function (response) {
                        console.log('reCAPTCHA', response)
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        // ...
                    },
                    'expired-callback': function () {
                        // Response expired. Ask user to solve reCAPTCHA again.
                        // ...
                    }
                });
                //
                this.appVerifier = window.recaptchaVerifier
            }, 1000)
        },
        async getProvince() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'provinces'
                }
            })
            console.log(response)
            this.provinces = response.data.data[0].name
        },
    },
    created() {
        this.initReCaptcha()
        this.getProvince();

    }
}
</script>

<style lang="scss" module></style>
